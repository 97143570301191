<template>
  <h2>Что такое break</h2>
  <p><strong>break</strong> используется для досрочного завершения цикла</p>
  <Code lang="py" :code="`
    for i in range(5):
        print(i)
        if i == 2:
            break
  `"/>
  <p>Результат:</p>
  <CodeResult :code="`
    0
    1
    2
  `"/>
  <h2>else у цикла</h2>
  <p>У циклов в python есть возможность использовать else. Код из else выполняется только если цикл не был досрочно завершён с помощью <strong>break</strong></p>
  <Code lang="py" :code="`
    for i in range(5):
        print(1)
        if i == 2:
            break
    else:
        print(-1)
  `"/>
  <p>Результат:</p>
  <CodeResult :code="`
    1
    1
    1
  `"/>
  <p>То есть, в данном случае цикл был досрочно завершён, поэтому else не выполнился. Обратите внимание, что else относится именно к for, а не к if</p>
  <Code lang="py" :code="`
    for i in range(5):
        print(1)
        if i == 7:
            break
    else:
        print(-1)
  `"/>
  <p>Результат:</p>
  <CodeResult :code="`
    1
    1
    1
    1
    1
    -1
  `"/>
  <p>В этом случае <strong>break</strong> не был выполнен, поскольку условие оказалось ложным для всех перебираемых чисел. Это значит, что цикл не был завершён досрочно, и поэтому блок else выполнился</p>
</template>

<script>
import Code from '@/components/ui/Code.vue'
import CodeResult from '@/components/ui/CodeResult.vue'

export default {
  components: { Code, CodeResult },
  props: ['task']
}
</script>
