<template>
  <h2>Как решать эту тему</h2>
  <p>Везде, где нужно содавать массивы определённой длины, нужно использовать умножение массива из одного элемента на число</p>
  <Code lang="py" :code="`
    a = [0] * 5
    print(a)
    print([10] * 3)
  `"/>
  <p>Результат:</p>
  <CodeResult :code="`
    [0, 0, 0, 0, 0]
    [10, 10, 10]
  `"/>
  <p>Преобразование range в list выглядит так:</p>
  <Code lang="py" :code="`
    a = list(range(7, 12))
    print(a)
    print(list(range(5)))
  `"/>
  <p>Результат:</p>
  <CodeResult :code="`
    [7, 8, 9, 10, 11]
    [0, 1, 2, 3, 4]
  `"/>
  <p>Генератор списков пишется так:</p>
  <Code lang="py" :code="`
    a = [i * 2 for i in range(7, 12)]
    print(a)
    print([True for i in range(3)])
  `"/>
  <p>Результат:</p>
  <CodeResult :code="`
    [14, 16, 18, 20, 22]
    [True, True, True]
  `"/>
</template>

<script>
import Code from '@/components/ui/Code.vue'
import CodeResult from '@/components/ui/CodeResult.vue'

export default {
  components: { Code, CodeResult },
  props: ['task']
}
</script>
