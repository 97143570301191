<template>
  <div class="task" ref="taskRef">
    <h2 class="m0 mt-medium">{{ task.name }}</h2>
    <div v-html="task.text" class="mt-medium" />
    <div v-if="task.note" class="mt">
      <strong>Примечание.</strong> <span v-html="task.note" />
    </div>
    <table class="task-table mt-medium">
      <caption />
      <thead>
        <tr>
          <th scope="col">
            <div>Входные данные</div>
            <div class="copy-button" @click="copyInput">
              Скопировать
            </div>
          </th>
          <th scope="col">Выходные данные</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-html="task.input" />
          <td v-html="task.output" />
        </tr>
        <tr v-for="(ex, i) of task.examples" :key="i">
          <td class="mono">
            <div class="pre">{{ ex.input }}</div>
          </td>
          <td class="mono">
            <div class="pre">{{ ex.output }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onUpdated, onMounted } from 'vue'

export default {
  name: 'LangPractice',
  props: ['task'],
  setup(props) {
    const MJ = window.MathJax
    const taskRef = ref(null)
    let promise = Promise.resolve()
    const reRenderTex = () => {
      promise = promise.then(() => MJ.typesetPromise([taskRef.value]))
    }
    onMounted(reRenderTex)
    onUpdated(reRenderTex)

    const copyInput = () => {
      const text = props.task.examples.map(ex => ex.input).join('\n')
      navigator.clipboard.writeText(text)
    }
    return {
      taskRef,
      copyInput
    }
  }
}
</script>

<style scoped>
.pre {
  overflow-x: auto;
  white-space: pre;
  scroll-padding-bottom: 5px;
  color: #800;
  font-size: 1.2rem;
}
.mr {
  margin-right: 30px;
}
.mono {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
.task-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
th {
  text-align: left;
}
td,
th {
  border: 1px solid #888;
  padding: 5px 10px;
  vertical-align: top;
}
tbody tr:first-child td {
  border-top: none;
  padding-top: 0;
  vertical-align: middle;
}
thead tr:last-child th {
  border-bottom: none;
}
.copy-button {
  position: absolute;
  top: 5px;
  right: 10px;
  border: 1px solid #999999;
  padding: 2px 5px;
  font-size: 0.6em;
  background: #eeeeee;
  cursor: pointer;
  font-weight: normal;
}
.copy-button:hover {
  background: #dddddd;
}
.copy-button:active {
  background: #999999;
}
</style>
