<template>
  <pre v-html="encoded" />
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'CodeResult',
  props: ['code'],
  setup(props) {
    const encoded = computed(() => {
      const lines = props.code.split('\n').slice(1, -1)
      let spaces = 0
      for (const c of lines[0]) {
        if (c === ' ') {
          spaces++
        } else {
          break
        }
      }
      return lines.map((line) => line.slice(spaces)).join('\n')
    })
    return {
      encoded
    }
  }
}
</script>

<style scoped>
pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #3389f3;
  border-radius: 15px;
  color: #333;
  font-size: 18px;
  overflow: auto;
  padding: 15px;
  white-space: break-spaces;
}
</style>
