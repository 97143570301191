import Simple from '@/components/task-templates/Simple'
import CodeTest from '@/components/task-templates/CodeTest'
import ListIntro from './ListIntro'
import BreakIntro from './BreakIntro'

export default [
  {
    component: ListIntro,
    label: '!'
  },
  {
    component: Simple,
    name: 'Список из нулей',
    text: 'Дано целое число \\(n\\). Создайте и выведите целочисленный список из \\(n\\) элементов, заполненный нулями',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 0, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первый и последний элемент',
    text: 'Дан целочисленный список \\(a\\). Выведите его первый и последний элементы через пробел',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Первый и последний элементы',
    examples: [
      {
        input: '[1]',
        output: '1 1'
      },
      {
        input: '[2, 3]',
        output: '2 3'
      },
      {
        input: '[4, 5, 6, 7]',
        output: '4 7'
      }
    ]
  },
  {
    component: Simple,
    name: '0..n-1 циклом',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(0\\) до \\(n\\) (\\(n\\) не включительно), используя цикл',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: '0..n-1 из range',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(0\\) до \\(n\\) (\\(n\\) не включительно), используя преобразование range в list',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: '0..n-1 генератором',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(0\\) до \\(n\\) (\\(n\\) не включительно), используя генератор списков',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: '1..n циклом',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(1\\) до \\(n\\) (включительно), используя цикл',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: '1..n из range',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(1\\) до \\(n\\) (включительно), используя преобразование range в list',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: '1..n из генератором',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(1\\) до \\(n\\) (включительно), используя генератор списков',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n раз n',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами \\(n\\)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[3, 3, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n..1 циклом',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(n\\) до \\(1\\) (включительно), используя цикл',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[3, 2, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n..1 из range',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(n\\) до \\(1\\) (включительно), используя преобразование range в list',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[3, 2, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n..1 генератором',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(n\\) до \\(1\\) (включительно), используя генератор списков',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[3, 2, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n-1..0 циклом',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(n\\) (не включительно) до \\(0\\) (включительно), используя цикл',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[2, 1, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n-1..0 из range',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(n\\) (не включительно) до \\(0\\) (включительно), используя преобразование range в list',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[2, 1, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'n-1..0 генератором',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) целых чисел, заполненный числами от \\(n\\) (не включительно) до \\(0\\) (включительно), используя генератор списков',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[2, 1, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение на месте',
    text: 'Дан целочисленный список \\(a\\). Измените этот список, умножив каждое число в нём на 2. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[]',
        output: '[]'
      },
      {
        input: '[1]',
        output: '[2]'
      },
      {
        input: '[-2, 0, 5]',
        output: '[-4, 0, 10]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение на месте через одного со второго',
    text: 'Дан целочисленный список \\(a\\). Измените этот список, умножив каждое второе число в нём на 2. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[]',
        output: '[]'
      },
      {
        input: '[1, 1, 1]',
        output: '[1, 2, 1]'
      },
      {
        input: '[1, 2, 2, 1]',
        output: '[1, 4, 2, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение на месте через одного с первого',
    text: 'Дан целочисленный список \\(a\\). Измените этот список, умножив каждое второе число в нём, начиная с первого, на 2. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[]',
        output: '[]'
      },
      {
        input: '[1, 1, 1]',
        output: '[2, 1, 2]'
      },
      {
        input: '[1, 2, 2, 1]',
        output: '[2, 2, 4, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение нечётных на месте',
    text: 'Дан целочисленный список \\(a\\). Измените этот список, умножив каждое нечётное число в нём на 2. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[]',
        output: '[]'
      },
      {
        input: '[1, 1, 1]',
        output: '[2, 2, 2]'
      },
      {
        input: '[1, 2, 3, 3]',
        output: '[2, 2, 6, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение нечётных, деление чётных',
    text: 'Дан целочисленный список \\(a\\). Измените этот список, умножив каждое нечётное число в нём на 2, а каждое чётное разделив на 2. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[]',
        output: '[]'
      },
      {
        input: '[1, 1, 2]',
        output: '[2, 2, 1]'
      },
      {
        input: '[2, 4, 3, 5]',
        output: '[1, 2, 6, 10]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обнуление отрицательных',
    text: 'Дан целочисленный список \\(a\\). Измените этот список, заменив каждое отрицательное на 0. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[]',
        output: '[]'
      },
      {
        input: '[-1, 1, 2]',
        output: '[0, 1, 2]'
      },
      {
        input: '[-1, 2, 3, -4]',
        output: '[0, 2, 3, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Модуль по списку',
    text: 'Дан целочисленный список \\(a\\). Измените этот список, заменив каждое отрицательное на противоположное положительное. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[]',
        output: '[]'
      },
      {
        input: '[-1, 1, 2]',
        output: '[1, 1, 2]'
      },
      {
        input: '[-1, 2, 3, -4]',
        output: '[1, 2, 3, 4]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод чётных с range',
    text: 'Дан целочисленный список \\(a\\). Выведите каждое чётное число из этого списка, используя цикл по range',
    note: 'Здесь лучше использовать цикл for-of',
    input: '\\(a\\) - список из целых чисел',
    output: 'Каждое число на отдельной строке',
    examples: [
      {
        input: '[1, 2, 4, 3]',
        output: '2\n4'
      },
      {
        input: '[2, 3, 4]',
        output: '2\n4'
      },
      {
        input: '[1, 2, 3, 4, 5, 6]',
        output: '2\n4\n6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод чётных в цикле по массиву',
    text: 'Дан целочисленный список \\(a\\). Выведите каждое чётное число из этого списка, используя цикл по массиву (без range)',
    note: 'Сам массив может быть использован как объект перебора вместо range. Например: for i in [10, 20, 30]: ...',
    input: '\\(a\\) - список из целых чисел',
    output: 'Каждое число на отдельной строке',
    examples: [
      {
        input: '[1, 2, 4, 3]',
        output: '2\n4'
      },
      {
        input: '[2, 3, 4]',
        output: '2\n4'
      },
      {
        input: '[1, 2, 3, 4, 5, 6]',
        output: '2\n4\n6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод через одного с range',
    text: 'Дан целочисленный список \\(a\\). Выведите каждое второе число из этого списка, используя цикл по range',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Каждое число на отдельной строке',
    examples: [
      {
        input: '[1, 2, 4, 3]',
        output: '2\n3'
      },
      {
        input: '[2, 3, 4]',
        output: '3'
      },
      {
        input: '[1, 2, 3, 4, 5, 6]',
        output: '2\n4\n6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод *4 и *7',
    text: 'Дан целочисленный список \\(a\\). Выведите каждое число из этого списка, которое заканчивается на 4 или 7',
    note: 'Когда не требуются индексы, лучше перебирать элементы без range',
    input: '\\(a\\) - список из целых чисел',
    output: 'Каждое число на отдельной строке',
    examples: [
      {
        input: '[41, 76, 0]',
        output: ''
      },
      {
        input: '[2, 3, 4]',
        output: '4'
      },
      {
        input: '[4, 24, 107]',
        output: '4\n24\n107'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод по делимости',
    text: 'Дан целочисленный список \\(a\\). Выведите каждое число из этого списка, которое не делится на 2 и 3',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Каждое число на отдельной строке',
    examples: [
      {
        input: '[10, 9, 8]',
        output: ''
      },
      {
        input: '[2, 3, 4, 5, 6]',
        output: '5'
      },
      {
        input: '[1, 5, 7, 9]',
        output: '1\n5\n7'
      }
    ]
  },
  {
    component: BreakIntro,
    label: '!'
  },
  {
    component: Simple,
    name: 'Поиск элемента с флагом',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). Выведите, входит ли \\(x\\) в \\(a\\), используя boolean-переменную',
    note: 'Когда элемент найден, нужно сделать break',
    input: '\\(a\\) - список из целых чисел',
    output: 'True или False',
    examples: [
      {
        input: '[], 1',
        output: 'False'
      },
      {
        input: '[10, 9, 9], 9',
        output: 'True'
      },
      {
        input: '[2, 3, 4, 5, 6], 7',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Поиск элемента с else у цикла',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). Выведите, входит ли \\(x\\) в \\(a\\), используя else у цикла',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'True или False',
    examples: [
      {
        input: '[], 1',
        output: 'False'
      },
      {
        input: '[10, 9, 9], 9',
        output: 'True'
      },
      {
        input: '[2, 3, 4, 5, 6], 7',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Поиск элемента через in',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). Выведите, входит ли \\(x\\) в \\(a\\), используя оператор in',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'True или False',
    examples: [
      {
        input: '[], 1',
        output: 'False'
      },
      {
        input: '[10, 9, 9], 9',
        output: 'True'
      },
      {
        input: '[2, 3, 4, 5, 6], 7',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс первого вхождения с переменной',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). Выведите индекс первого вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1. Для запоминания индекса использовать переменную',
    note: 'Нужно использовать break, когда элемент найден',
    input: '\\(a\\) - список из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: '[], 1',
        output: '-1'
      },
      {
        input: '[10, 9, 9], 9',
        output: '1'
      },
      {
        input: '[7, 3, 4, 5, 6], 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс первого вхождения с else у цикла',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). Выведите индекс первого вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1, используя else у цикла',
    note: 'Нужно использовать break, когда элемент найден',
    input: '\\(a\\) - список из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: '[], 1',
        output: '-1'
      },
      {
        input: '[10, 9, 9], 9',
        output: '1'
      },
      {
        input: '[7, 3, 4, 5, 6], 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс первого вхождения через find',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). С помощью метода find выведите индекс первого вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: '[], 1',
        output: '-1'
      },
      {
        input: '[10, 9, 9], 9',
        output: '1'
      },
      {
        input: '[7, 3, 4, 5, 6], 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс последнего вхождения с переменной',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). Выведите, индекс последнего вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1, используя переменную для запоминания результата',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: '[], 1',
        output: '-1'
      },
      {
        input: '[10, 9, 9], 9',
        output: '2'
      },
      {
        input: '[7, 3, 4, 5, 6], 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс последнего вхождения с else у цикла',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). Выведите, индекс последнего вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1, используя else у цикла',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: '[], 1',
        output: '-1'
      },
      {
        input: '[10, 9, 9], 9',
        output: '2'
      },
      {
        input: '[7, 3, 4, 5, 6], 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс последнего вхождения через rfind',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). С помощью метода rfind выведите индекс последнего вхождения \\(x\\) в \\(a\\). Если \\(x\\) не входит в \\(a\\), выведите -1',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: '[], 1',
        output: '-1'
      },
      {
        input: '[10, 9, 9], 9',
        output: '2'
      },
      {
        input: '[7, 3, 4, 5, 6], 7',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётные числа от 0 циклом',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) чисел, заполненный чётными числами от 0 по порядку (0, 2, 4...), используя цикл',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 2, 4]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётные числа от 0 из range',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) чисел, заполненный чётными числами от 0 по порядку (0, 2, 4...), используя преобразование range в list',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 2, 4]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётные числа от 0 генератором',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) чисел, заполненный чётными числами от 0 по порядку (0, 2, 4...), используя генератор списков',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[0]'
      },
      {
        input: '3',
        output: '[0, 2, 4]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Нечётные числа циклом',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) чисел, заполненный нечётными числами от 1 по порядку (1, 3, 5...), используя цикл',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 3, 5]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Нечётные числа из range',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) чисел, заполненный нечётными числами от 1 по порядку (1, 3, 5...), используя преобразование range в list',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 3, 5]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Нечётные числа генератором',
    text: 'Дано целое число \\(n\\). Создайте и выведите список из \\(n\\) чисел, заполненный нечётными числами от 1 по порядку (1, 3, 5...), используя генератор списков',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '0',
        output: '[]'
      },
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 3, 5]'
      }
    ]
  },
  {
    component: CodeTest,
    label: '+',
    lang: 'py',
    name: 'Список - ссылочный тип',
    text: 'Напишите функцию \\(link\\_testing\\), принимающую два целочисленных списка \\(a\\) и \\(b\\). В списке \\(a\\) замените первый элемент на последний элемент из \\(b\\). Затем перезапишите переменную \\(b\\), записав в неё \\(a\\). Выведите \\(b\\)',
    note: 'Посмотрите внимательно на код, и убедитесь, что понятно, почему результат именно такой',
    examples: [
      {
        input: `
          a = [1, 2, 3]
          b = [4, 5, 6]
          link_testing(a, b)
          print(a, b)
          c = b
          link_testing(b, c)
          print(b, c)
        `,
        output: `
          [6, 2, 3]
          [6, 2, 3] [4, 5, 6]
          [6, 5, 6]
          [6, 5, 6] [6, 5, 6]
        `
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение без мутации циклом',
    text: 'Дан целочисленный список \\(a\\). Создайте новый список такой же длины. Заполните его числами из \\(a\\), умноженными на 2, используя цикл. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[2]'
      },
      {
        input: '[1, 2]',
        output: '[2, 4]'
      },
      {
        input: '[5, 10, 5]',
        output: '[10, 20, 10]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение без мутации генератором',
    text: 'Дан целочисленный список \\(a\\). Создайте новый список такой же длины и заполните его числами из \\(a\\), умноженными на 2, используя генератор. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[2]'
      },
      {
        input: '[1, 2]',
        output: '[2, 4]'
      },
      {
        input: '[5, 10, 5]',
        output: '[10, 20, 10]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение через одного без мутации циклом',
    text: 'Дан целочисленный список \\(a\\). Создайте новый список такой же длины. Заполните его числами из \\(a\\), только каждое второе число умножьте на 2, используя цикл. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[1]'
      },
      {
        input: '[1, 2, 3]',
        output: '[1, 4, 3]'
      },
      {
        input: '[1, 1, 1, 1]',
        output: '[1, 2, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение через одного без мутации генератором',
    text: 'Дан целочисленный список \\(a\\). Создайте новый список такой же длины и заполните его числами из \\(a\\), только каждое второе число умножьте на 2, используя генератор списка с тернарным оператором. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[1]'
      },
      {
        input: '[1, 2, 3]',
        output: '[1, 4, 3]'
      },
      {
        input: '[1, 1, 1, 1]',
        output: '[1, 2, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение нечётных без мутации генератором',
    text: 'Дан целочисленный список \\(a\\). Создайте новый список такой же длины и заполните его числами из \\(a\\), только каждое нечётное число умножьте на 2, используя генератор списков с тернарным оператором. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[2]'
      },
      {
        input: '[1, 3, 2]',
        output: '[2, 6, 2]'
      },
      {
        input: '[1, 1, 1, 1]',
        output: '[2, 2, 2, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма по спискам генератором',
    text: 'Дано два целочисленных списка \\(a\\) и \\(b\\) одинаковой длины. Создайте новый целочисленный список той же длины и заполните его суммами соответствующих элементов из \\(a\\) и \\(b\\) (первый с первым, второй со вторым и т. д.), используя генератор списков. Выведите новый список',
    note: null,
    input: '\\(a\\) и \\(b\\) - списки из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2], [2, 3]',
        output: '[3, 5]'
      },
      {
        input: '[1, 2, 3], [3, 2, 1]',
        output: '[4, 4, 4]'
      },
      {
        input: '[1, 1, 1], [3, 2, 1]',
        output: '[4, 3, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимумы по спискам',
    text: 'Дано два целочисленных списка \\(a\\) и \\(b\\) одинаковой длины. Создайте новый целочисленный список той же длины и заполните его максимальными числами из соответствующих элементов \\(a\\) и \\(b\\) (первый с первым, второй со вторым и т. д.), используя генератор списков с тернарным оператором. Выведите новый список',
    note: null,
    input: '\\(a\\) и \\(b\\) - списки из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2], [2, 3]',
        output: '[2, 3]'
      },
      {
        input: '[1, 2, 3], [3, 2, 1]',
        output: '[3, 2, 3]'
      },
      {
        input: '[1, 1, 1], [3, 2, 1]',
        output: '[3, 2, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Модули разности по спискам генератором',
    text: 'Дано два целочисленных списка \\(a\\) и \\(b\\) одинаковой длины. Создайте новый целочисленный список той же длины. Заполните его неотрицательными разницами чисел из соответствующих элементов \\(a\\) и \\(b\\), используя генератор списков с тернарным оператором. Выведите новый список',
    note: null,
    input: '\\(a\\) и \\(b\\) - списки из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2], [2, 3]',
        output: '[1, 1]'
      },
      {
        input: '[1, 2, 3], [3, 2, 1]',
        output: '[2, 0, 2]'
      },
      {
        input: '[1, 1, 1], [3, 2, 1]',
        output: '[2, 1, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Список True/False по условию генератором',
    text: 'Дан целочисленный список \\(a\\). Создайте новый булевый список той же длины. Перебирая значения \\(a\\) по порядку, записывайте в него True, если встретилось положительное число, и False в противном случае, используя генератор списков. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[True]'
      },
      {
        input: '[1, -2, 0]',
        output: '[True, False, False]'
      },
      {
        input: '[0, 1, 2]',
        output: '[False, True, True]'
      }
    ]
  },
  {
    component: Simple,
    name: 'x..y циклом',
    text: 'Даны целые числа \\(x\\) и \\(y\\) (\\(x < y\\)). Создайте и выведите список, заполненный числами от \\(x\\) до \\(y\\) (включительно), используя цикл',
    note: 'Длину списка нужно вычислить при создании',
    input: '\\(x\\) и \\(y\\) - целые',
    output: 'Получившийся список',
    examples: [
      {
        input: '0, 1',
        output: '[0, 1]'
      },
      {
        input: '5, 7',
        output: '[5, 6, 7]'
      },
      {
        input: '-2, 0',
        output: '[-2, -1, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'x..y из range',
    text: 'Даны целые числа \\(x\\) и \\(y\\) (\\(x < y\\)). Создайте и выведите список, заполненный числами от \\(x\\) до \\(y\\) (включительно), используя преобразование range в list',
    note: 'Длину списка нужно вычислить при создании',
    input: '\\(x\\) и \\(y\\) - целые',
    output: 'Получившийся список',
    examples: [
      {
        input: '0, 1',
        output: '[0, 1]'
      },
      {
        input: '5, 7',
        output: '[5, 6, 7]'
      },
      {
        input: '-2, 0',
        output: '[-2, -1, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'y-1..x+1 циклом',
    text: 'Даны целые числа \\(x\\) и \\(y\\) (\\(x < y\\)). Создайте и выведите список, заполненный числами от \\(y\\) (не включительно) до \\(x\\) (не включительно), используя цикл',
    note: null,
    input: 'Заданы целые \\(x\\) и \\(y\\). (\\(-100 \\le x < y \\le 100\\))',
    output: 'Получившийся список',
    examples: [
      {
        input: '0, 1',
        output: '[]'
      },
      {
        input: '5, 7',
        output: '[6]'
      },
      {
        input: '-2, 2',
        output: '[1, 0, -1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'y-1..x+1 из range',
    text: 'Даны целые числа \\(x\\) и \\(y\\) (\\(x < y\\)). Создайте и выведите список, заполненный числами от \\(y\\) (не включительно) до \\(x\\) (не включительно), используя преобразование range в list',
    note: null,
    input: 'Заданы целые \\(x\\) и \\(y\\). (\\(-100 \\le x < y \\le 100\\))',
    output: 'Получившийся список',
    examples: [
      {
        input: '0, 1',
        output: '[]'
      },
      {
        input: '5, 7',
        output: '[6]'
      },
      {
        input: '-2, 2',
        output: '[1, 0, -1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'По два раза циклом',
    text: 'Дано целое число \\(n\\). Создайте и выведите список, заполненный числами от \\(1\\) до \\(n\\) (включительно), каждое число по два раза, используя цикл',
    note: 'if не использовать',
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '1',
        output: '[1, 1]'
      },
      {
        input: '2',
        output: '[1, 1, 2, 2]'
      },
      {
        input: '3',
        output: '[1, 1, 2, 2, 3, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'По два раза генератором',
    text: 'Дано целое число \\(n\\). Создайте и выведите список, заполненный числами от \\(1\\) до \\(n\\) (включительно), каждое число по два раза, используя генератор списков',
    note: 'if не использовать',
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '1',
        output: '[1, 1]'
      },
      {
        input: '2',
        output: '[1, 1, 2, 2]'
      },
      {
        input: '3',
        output: '[1, 1, 2, 2, 3, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Растущая прогрессия',
    text: 'Дано целое число \\(n\\). Создайте и выведите список длины \\(n\\), в котором первое число 1, а каждое следующее больше на свой порядковый номер (\\(1, 1+2, 1+2+3, ...\\))',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '1',
        output: '[1]'
      },
      {
        input: '3',
        output: '[1, 3, 6]'
      },
      {
        input: '5',
        output: '[1, 3, 6, 10, 15]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимальная сумма соседей',
    text: 'Дан целочисленный список \\(a\\), содержащий минимум 2 элемента. Выведите самую большую сумму среди всех пар соседних элементов',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Максимальная сумма',
    examples: [
      {
        input: '[2, 3]',
        output: '5'
      },
      {
        input: '[2, 1, 3, 0]',
        output: '4'
      },
      {
        input: '[2, 7, 0, 2, 9]',
        output: '11'
      },
      {
        input: '[-2, -7, 0, -2, -9]',
        output: '-2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Ряд Фибоначчи',
    text: 'Дано целое число \\(n\\) (\\(n \\ge 2\\)). Создайте и выведите список длины \\(n\\), в котором первые два числа единицы, а каждое следующее - это сумма двух предыдущих',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Получившийся список',
    examples: [
      {
        input: '2',
        output: '[1, 1]'
      },
      {
        input: '5',
        output: '[1, 1, 2, 3, 5]'
      },
      {
        input: '7',
        output: '[1, 1, 2, 3, 5, 8, 13]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен соседей',
    text: 'Дан целочисленный список \\(a\\). Измените список таким образом, чтобы каждый второй элемент обменялся местами с предыдущим. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2, 3, 4]',
        output: '[2, 1, 4, 3]'
      },
      {
        input: '[1, 2, 2, 1, 3]',
        output: '[2, 1, 1, 2, 3]'
      },
      {
        input: '[1, 5, 7]',
        output: '[5, 1, 7]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обмен соседей без мутации',
    text: 'Дан целочисленный список \\(a\\). Создайте новый целочисленный список той же длины. Заполните его числами из \\(a\\), но каждый второй элемент должен обменяться местами с левым соседом. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2]',
        output: '[2, 1]'
      },
      {
        input: '[1, 2, 3]',
        output: '[2, 1, 3]'
      },
      {
        input: '[10, 5, 1, 0]',
        output: '[5, 10, 0, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Список накопительных сумм',
    text: 'Дан целочисленный список \\(a\\). Создайте новый целочисленный список той же длины. Первый элемент взять из \\(a\\). На каждое следующее место запишите сумму предыдущего элемента и соответствующего элемента из \\(a\\). Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 1, 1]',
        output: '[1, 2, 3]'
      },
      {
        input: '[2, 2, 2]',
        output: '[2, 4, 6]'
      },
      {
        input: '[10, 5, 1, 0]',
        output: '[10, 15, 16, 16]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разворот циклом',
    text: 'Дан целочисленный список \\(a\\). Измените список таким образом, чтобы элементы в нём встали в противоположном порядке (развернуть список задом наперёд), используя цикл. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[1]'
      },
      {
        input: '[10, 20, 15]',
        output: '[15, 20, 10]'
      },
      {
        input: '[5, 3, 7, 4]',
        output: '[4, 7, 3, 5]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разворот через reverse',
    text: 'Дан целочисленный список \\(a\\). Измените список таким образом, чтобы элементы в нём встали в противоположном порядке (развернуть список задом наперёд), используя метод reverse. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[1]'
      },
      {
        input: '[10, 20, 15]',
        output: '[15, 20, 10]'
      },
      {
        input: '[5, 3, 7, 4]',
        output: '[4, 7, 3, 5]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разворот без мутации циклом',
    text: 'Дан целочисленный список \\(a\\). Создайте новый целочисленный список той же длины. Запишите в него элементы \\(a\\) в обратном порядке, используя цикл. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[1]'
      },
      {
        input: '[1, 0, 2]',
        output: '[2, 0, 1]'
      },
      {
        input: '[9, 7, 4]',
        output: '[4, 7, 9]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разворот без мутации генератором',
    text: 'Дан целочисленный список \\(a\\). Создайте новый целочисленный список той же длины и запишите в него элементы \\(a\\) в обратном порядке, используя генератор списков. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[1]'
      },
      {
        input: '[1, 0, 2]',
        output: '[2, 0, 1]'
      },
      {
        input: '[9, 7, 4]',
        output: '[4, 7, 9]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разворот без мутации через срез',
    text: 'Дан целочисленный список \\(a\\). Создайте новый целочисленный список той же длины и запишите в него элементы \\(a\\) в обратном порядке, используя срез. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[1]'
      },
      {
        input: '[1, 0, 2]',
        output: '[2, 0, 1]'
      },
      {
        input: '[9, 7, 4]',
        output: '[4, 7, 9]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Перестановка половин',
    text: 'Дан целочисленный список \\(a\\) чётной длины. Измените список таким образом, чтобы левая и правая половины поменялись местами. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2]',
        output: '[2, 1]'
      },
      {
        input: '[1, 2, 3, 4]',
        output: '[3, 4, 1, 2]'
      },
      {
        input: '[1, 2, 3, 3, 2, 1]',
        output: '[3, 2, 1, 1, 2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимальная сумма из префиксов',
    text: 'Дан целочисленный список \\(a\\), содержащий минимум 1 элемент. Выведите максимальную сумму из любого набора соседних элементов от первого до любого другого',
    note: 'Например, есть список [2, 3, -1, 0]. Из него возможно взять 4 набора соседних элементов, начиная от первого: [2], [2, 3], [2, 3, -1] и [2, 3, -1, 0]. Максимальная сумма у второго набора. Её и надо вывести',
    input: '\\(a\\) - список из целых чисел',
    output: 'Максимальная сумма',
    examples: [
      {
        input: '[2, 3]',
        output: '5'
      },
      {
        input: '[-1, -2, -3]',
        output: '-1'
      },
      {
        input: '[2, 3, -1, 0]',
        output: '5'
      },
      {
        input: '[2, 3, -1, 0, 2]',
        output: '6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Подсчёт с помощью списка',
    text: 'Дан целочисленный список \\(a\\), состоящий из чисел в диапазоне от 0 до 5. Выведите список из 6 чисел, в котором по индексу 0 лежит количество нулей в \\(a\\), по индексу 1 - количество единиц и т. д.',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[]',
        output: '[0, 0, 0, 0, 0, 0]'
      },
      {
        input: '[0, 0, 0, 2, 3, 3]',
        output: '[3, 0, 1, 2, 0, 0]'
      },
      {
        input: '[5, 2, 4, 3, 1, 0]',
        output: '[1, 1, 1, 1, 1, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сдвиг вправо',
    text: 'Дан целочисленный список \\(a\\). Измените список таким образом, чтобы каждый элемент сдвинулся вправо на одну позицию, а последний элемент стал первым. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2, 3]',
        output: '[3, 1, 2]'
      },
      {
        input: '[4, 1, 2, 3]',
        output: '[3, 4, 1, 2]'
      },
      {
        input: '[3, 4, 1, 2]',
        output: '[2, 3, 4, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сдвиг влево',
    text: 'Дан целочисленный список \\(a\\). Измените список таким образом, чтобы каждый элемент сдвинулся влево на одну позицию, а первый элемент стал последним. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2, 3]',
        output: '[2, 3, 1]'
      },
      {
        input: '[4, 1, 2, 3]',
        output: '[1, 2, 3, 4]'
      },
      {
        input: '[3, 4, 1, 2]',
        output: '[4, 1, 2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обнуление левых',
    text: 'Дан целочисленный список \\(a\\). Измените этот список, обнулив каждый элемент, который больше своего правого соседа. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2]',
        output: '[1, 2]'
      },
      {
        input: '[3, 2, 1]',
        output: '[0, 0, 1]'
      },
      {
        input: '[1, 2, 1, 2]',
        output: '[1, 0, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обнуление правых',
    text: 'Дан целочисленный список \\(a\\). Измените этот список, обнулив каждый элемент, который больше своего левого соседа. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1, 2, 3, 4]',
        output: '[1, 0, 0, 0]'
      },
      {
        input: '[3, 2, 1, 0]',
        output: '[3, 2, 1, 0]'
      },
      {
        input: '[1, 3, 2, 4]',
        output: '[1, 0, 2, 0]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез через одного',
    text: 'Дан целочисленный список \\(a\\). Создайте новый список, запишите в него только каждый второй элемент \\(a\\). Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1]',
        output: '[]'
      },
      {
        input: '[1, 3, 2]',
        output: '[3]'
      },
      {
        input: '[1, 2, 1, 3]',
        output: '[2, 3]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез от m до n',
    text: 'Дан целочисленный список \\(a\\), а также целые числа \\(m\\) и \\(n\\) (\\(1 \\le m \\le n \\le |a|\\)). Создайте новый целочисленный список, заполненный числами из \\(a\\), начиная с позиции \\(m\\), заканчивая позицией \\(n\\) (обе включительно). Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(m\\) и \\(n\\) - целые положительные',
    output: 'Получившийся список',
    examples: [
      {
        input: '[5, 4, 3], 2, 3',
        output: '[4, 3]'
      },
      {
        input: '[5, 6, 7, 8], 1, 3',
        output: '[5, 6, 7]'
      },
      {
        input: '[9, 8, 7, 1, 2, 3], 3, 5',
        output: '[7, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез от m до n через slice',
    text: 'Дан целочисленный список \\(a\\), а также целые числа \\(m\\) и \\(n\\) (\\(1 \\le m \\le n \\le |a|\\)). Создайте новый целочисленный список, заполненный числами из \\(a\\), начиная с позиции \\(m\\), заканчивая позицией \\(n\\) (обе включительно), используя метод slice. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(m\\) и \\(n\\) - целые положительные',
    output: 'Получившийся список',
    examples: [
      {
        input: '[5, 4, 3], 2, 3',
        output: '[4, 3]'
      },
      {
        input: '[5, 6, 7, 8], 1, 3',
        output: '[5, 6, 7]'
      },
      {
        input: '[9, 8, 7, 1, 2, 3], 3, 5',
        output: '[7, 1, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Повторение списка',
    text: 'Дан целочисленный список \\(a\\), а также целое число \\(n\\). Создайте новый целочисленный список с длиной в \\(n\\) раз больше \\(a\\), заполненный числами из \\(a\\), повторяя последовательность \\(n\\) раз. Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(n\\) - целое неотрицательное',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1], 3',
        output: '[1, 1, 1]'
      },
      {
        input: '[1, 2], 2',
        output: '[1, 2, 1, 2]'
      },
      {
        input: '[9, 8], 3',
        output: '[9, 8, 9, 8, 9, 8]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Копирование с добавлением в конец',
    text: 'Дан целочисленный список \\(a\\), а также целое число \\(x\\). Создайте новый целочисленный список с длиной на 1 больше, чем \\(a\\). Заполните список всеми элементами \\(a\\), а на последнее место запишите \\(x\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(x\\) - целое',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1], 3',
        output: '[1, 3]'
      },
      {
        input: '[1, 2], 2',
        output: '[1, 2, 2]'
      },
      {
        input: '[9, 8, 7, 6], 5',
        output: '[9, 8, 7, 6, 5]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Копирование с добавлением в начало',
    text: 'Дан целочисленный список \\(a\\), а также целое число \\(x\\). Создайте новый целочисленный список с длиной на 1 больше, чем \\(a\\). Заполните список всеми элементами \\(a\\), а на первое место запишите \\(x\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(x\\) - целое',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1], 3',
        output: '[3, 1]'
      },
      {
        input: '[1, 2], 2',
        output: '[2, 1, 2]'
      },
      {
        input: '[9, 8, 7, 6], 5',
        output: '[5, 9, 8, 7, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Копирование с удалением',
    text: 'Дан целочисленный список \\(a\\), а также целое число \\(n\\). Создайте новый целочисленный список с длиной на 1 меньше, чем \\(a\\). Заполните список всеми элементами \\(a\\), кроме элемента по индексу \\(n\\) (\\(0 \\le n < a.length\\))',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(n\\) - целое неотрицательное',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1], 0',
        output: '[]'
      },
      {
        input: '[1, 2, 3], 2',
        output: '[1, 2]'
      },
      {
        input: '[9, 8, 7, 6], 1',
        output: '[9, 7, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Конкатенация списков',
    text: 'Даны целочисленные списки \\(a\\) и \\(b\\). Создайте новый целочисленный список, заполненный всеми числами из \\(a\\) и \\(b\\) (сначала все элементы из \\(a\\), затем все из \\(b\\)). Выведите новый список',
    note: null,
    input: '\\(a\\) и \\(b\\) - списки из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1], [2]',
        output: '[1, 2]'
      },
      {
        input: '[1, 2], [3]',
        output: '[1, 2, 3]'
      },
      {
        input: '[9, 8], [9, 8, 7]',
        output: '[9, 8, 9, 8, 7]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Конкатенация списков плюсом',
    text: 'Даны целочисленные списки \\(a\\) и \\(b\\). Создайте новый целочисленный список, заполненный всеми числами из \\(a\\) и \\(b\\) (сначала все элементы из \\(a\\), затем все из \\(b\\)), используя + для массивов. Выведите новый список',
    note: null,
    input: '\\(a\\) и \\(b\\) - списки из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1], [2]',
        output: '[1, 2]'
      },
      {
        input: '[1, 2], [3]',
        output: '[1, 2, 3]'
      },
      {
        input: '[9, 8], [9, 8, 7]',
        output: '[9, 8, 9, 8, 7]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Упорядочить левый',
    text: 'Дан целочисленный список \\(a\\). Все элементы, кроме первого, упорядочены по возрастанию. Измените список таким образом, чтобы он стал полностью упорядоченным. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[3, 1, 2, 4]',
        output: '[1, 2, 3, 4]'
      },
      {
        input: '[2, 1, 1, 2, 2]',
        output: '[1, 1, 2, 2, 2]'
      },
      {
        input: '[6, 2, 3, 4, 5]',
        output: '[2, 3, 4, 5, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Упорядочить правый',
    text: 'Дан целочисленный список \\(a\\). Все элементы, кроме последнего, упорядочены по убыванию. Измените список таким образом, чтобы он стал полностью упорядоченным. Выведите список \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[5, 4, 2, 3]',
        output: '[5, 4, 3, 2]'
      },
      {
        input: '[2, 2, 1, 1, 2]',
        output: '[2, 2, 2, 1, 1]'
      },
      {
        input: '[5, 4, 3, 2, 6]',
        output: '[6, 5, 4, 3, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обрезать по краям',
    text: 'Дан целочисленный список \\(a\\). Скопируйте список, удалив все нули в начале и в конце',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[0, 0, 1, 2, 0]',
        output: '[1, 2]'
      },
      {
        input: '[2, 0, 0, 3]',
        output: '[2, 0, 0, 3]'
      },
      {
        input: '[0, 0, 0, 0]',
        output: '[]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вставка списка с удалением',
    text: 'Даны целочисленные списки \\(a\\) и \\(b\\), а также целые числа \\(n\\) и \\(m\\). Скопируйте список, вставив вместо элементов с индексами от \\(m\\) включительно до \\(n\\) невключительно элементы списка \\(b\\)',
    note: null,
    input: '\\(a\\), \\(b\\) - списки из целых чисел, \\(n\\) и \\(m\\) - целые неотрицательные числа',
    output: 'Получившийся список',
    examples: [
      {
        input: '[4, 5, 6, 7, 8], [9, 10], 1, 4',
        output: '[4, 9, 10, 8]'
      },
      {
        input: '[2, 3], [], 1, 2',
        output: '[2]'
      },
      {
        input: '[1, 2, 3, 4], [5], 1, 1',
        output: '[1, 5, 2, 3, 4]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез от m с шагом n',
    text: 'Дан целочисленный список \\(a\\), а также целые числа \\(m\\) и \\(n\\) (\\(1 \\le m \\le n \\le |a|\\)). Создайте новый целочисленный список, заполненный числами из \\(a\\), начиная с позиции \\(m\\), с шагом \\(n\\). Выведите новый список',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(m\\) и \\(n\\) - целые положительные',
    output: 'Получившийся список',
    examples: [
      {
        input: '[5, 4, 3], 1, 1',
        output: '[5, 4, 3]'
      },
      {
        input: '[5, 6, 7, 8], 1, 2',
        output: '[5, 7]'
      },
      {
        input: '[9, 8, 7, 1, 2, 3], 2, 3',
        output: '[8, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Слияние списков',
    text: 'Даны упорядоченные по неубыванию целочисленные списки \\(a\\) и \\(b\\). Создайте новый список, в котором будут все элементы из \\(a\\) и \\(b\\) так же в упорядоченном по неубыванию виде',
    note: null,
    input: '\\(a\\), \\(b\\) - списки из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[4, 7, 9], [3, 5]',
        output: '[3, 4, 5, 7, 9]'
      },
      {
        input: '[2, 3], [1, 2, 4]',
        output: '[1, 2, 2, 3, 4]'
      },
      {
        input: '[1, 3, 5], [2, 4, 6]',
        output: '[1, 2, 3, 4, 5, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Простая деструктуризация',
    text: 'Дан целочисленный список \\(a\\), состоящий из двух элементов. Запишите элементы списка в две новые переменные \\(x\\) и \\(y\\), используя синтаксис присваивания с деструктуризацией (списочное присваивание). Выведите произведение \\(x\\) и \\(y\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: 'Произведение элементов',
    examples: [
      {
        input: '[5, 3]',
        output: '15'
      },
      {
        input: '[2, 6]',
        output: '12'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация на элемент и хвост',
    text: 'Дан целочисленный список \\(a\\), содержащий хотя бы один элемент. С помощью деструктуризации разложите его на первый элемент \\(x\\) и список из остальных элементов \\(y\\). Выведите \\(x\\) и \\(y\\)',
    note: 'Список из остальных элементов получить с помощью оператора rest',
    input: '\\(a\\) - список из целых чисел',
    output: '\\(x\\), \\(y\\) через пробел',
    examples: [
      {
        input: '[1]',
        output: '1 []'
      },
      {
        input: '[2, 5, 6]',
        output: '2 [5, 6]'
      },
      {
        input: '[4, 5, 8, 9]',
        output: '4 [5, 8, 9]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация на два элемента и хвост',
    text: 'Дан целочисленный список \\(a\\), содержащий хотя бы два элемента. С помощью деструктуризации разложите его на первый элемент \\(x\\), второй элемент \\(y\\) и список из остальных элементов \\(z\\) (с помощью rest). Выведите \\(x\\), \\(y\\) и \\(z\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел',
    output: '\\(x\\), \\(y\\), \\(z\\) через пробел',
    examples: [
      {
        input: '[1, 2]',
        output: '1 2 []'
      },
      {
        input: '[2, 5, 6]',
        output: '2 5 [6]'
      },
      {
        input: '[4, 5, 8, 9]',
        output: '4 5 [8, 9]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Деструктуризация в for',
    text: 'Дан список \\(a\\), состоящий из пар чисел (каждая пара - список длины 2). Выведите сумму каждой пары, используя деструктуризацию в цикле for',
    note: null,
    input: '\\(a\\) - целочисленная матрица ?x2',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '[[2, 5]]',
        output: '7'
      },
      {
        input: '[[1, 2], [3, 4]]',
        output: '3\n7'
      },
      {
        input: '[[5, 1], [7, 8], [5, 5]]',
        output: '6\n15\n10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Минимальная площадь',
    text: 'Дан список \\(a\\), состоящий из пар чисел (хотя бы одной). Каждая пара - список длины 2. Числа в паре - стороны прямоугольника. Выведите минимальную площадь прямоугольника',
    note: null,
    input: '\\(a\\) - целочисленная матрица ?x2',
    output: 'Минимальная площадь',
    examples: [
      {
        input: '[[5, 4]]',
        output: '20'
      },
      {
        input: '[[3, 3], [2, 2], [4, 5]]',
        output: '4'
      },
      {
        input: '[[1, 3], [3, 1], [2, 1]]',
        output: '2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимальный периметр',
    text: 'Дан список \\(a\\), состоящий из пар чисел (хотя бы одной). Каждая пара - список длины 2. Числа в паре - стороны прямоугольника. Выведите максимальный периметр прямоугольника',
    note: null,
    input: '\\(a\\) - целочисленная матрица ?x2',
    output: 'Максимальный периметр',
    examples: [
      {
        input: '[[5, 3]]',
        output: '16'
      },
      {
        input: '[[1, 3], [3, 1], [2, 1]]',
        output: '8'
      },
      {
        input: '[[5, 5], [3, 4], [4, 4]]',
        output: '20'
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread для добавления в конец без мутации',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). Создайте и выведите новый список с помощью оператора spread, содержащий все элементы \\(a\\), а также число \\(x\\) в конце',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(x\\) - целое число',
    output: 'Получившийся список',
    examples: [
      {
        input: '[], 7',
        output: '[7]'
      },
      {
        input: '[1, 2], 3',
        output: '[1, 2, 3]'
      },
      {
        input: '[4, 5, 6], 10',
        output: '[4, 5, 6, 10]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread для добавления в начало без мутации',
    text: 'Дан целочисленный список \\(a\\) и число \\(x\\). Создайте и выведите новый список с помощью оператора spread, содержащий в начале элемент \\(x\\), а затем все элементы \\(a\\)',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(x\\) - целое число',
    output: 'Получившийся список',
    examples: [
      {
        input: '[], 7',
        output: '[7]'
      },
      {
        input: '[1, 2], 3',
        output: '[3, 1, 2]'
      },
      {
        input: '[4, 5, 6], 10',
        output: '[10, 4, 5, 6]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread для конкатенации без мутации',
    text: 'Даны целочисленные списки \\(a\\) и \\(b\\). Создайте новый целочисленный список с помощью оператора spread, заполненный всеми числами из \\(a\\) и \\(b\\) (сначала все элементы из \\(a\\), затем все из \\(b\\)). Выведите новый список',
    note: null,
    input: '\\(a\\) и \\(b\\) - списки из целых чисел',
    output: 'Получившийся список',
    examples: [
      {
        input: '[1], [2]',
        output: '[1, 2]'
      },
      {
        input: '[1, 2], [3]',
        output: '[1, 2, 3]'
      },
      {
        input: '[9, 8], [9, 8, 7]',
        output: '[9, 8, 9, 8, 7]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Spread для произвольного заполнения',
    text: 'Даны целочисленные списки \\(a\\), \\(b\\) и число \\(x\\). Создайте и выведите новый список с помощью оператора spread, содержащий в начале все элементы \\(a\\), затем число \\(x\\), а затем все элементы \\(b\\)',
    note: null,
    input: '\\(a\\), \\(b\\) - списки из целых чисел, \\(x\\) - целое число',
    output: 'Получившийся список',
    examples: [
      {
        input: '[], [], 7',
        output: '[7]'
      },
      {
        input: '[1], [5], 3',
        output: '[1, 3, 5]'
      },
      {
        input: '[1, 2], [2, 1], 3',
        output: '[1, 2, 3, 2, 1]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Другие методы: append',
    text: 'Дан целочисленный список \\(a\\) и целое число \\(n\\). Измените список таким образом, чтобы число \\(n\\) добавилось в конец списка, увеличив его длину, используя метод append. Выведите список',
    note: null,
    input: '\\(a\\) - список из целых чисел, \\(n\\) - целое число',
    output: 'Получившийся список',
    examples: [
      {
        input: '[], 7',
        output: '[7]'
      },
      {
        input: '[1, 3], 2',
        output: '[1, 3, 2]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Другие методы: pop',
    text: 'Дан целочисленный список \\(a\\). Измените список таким образом, чтобы последнее число было удалено из списка, используя метод pop. Выведите удалённое число, затем сам список через пробел',
    note: 'Обратите внимание, что метод pop возвращает удалённый элемент',
    input: '\\(a\\) - список из целых чисел',
    output: 'Удалённое число и получившийся список',
    examples: [
      {
        input: '[7]',
        output: '7 []'
      },
      {
        input: '[1, 3, 2]',
        output: '2 [1, 3]'
      }
    ]
  }
]
