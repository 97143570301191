<template>
  <div class="task" ref="taskRef">
    <h2 class="m0 mt-medium">{{ task.name }}</h2>
    <div v-html="task.text" class="mt-medium" />
    <div v-if="task.note" class="mt">
      <strong>Примечание.</strong> <span v-html="task.note" />
    </div>
    <div class="mt-medium">
      <div>Код для тестирования:</div>
      <div v-for="(ex, i) of task.examples" :key="i" class="mt">
        <Code :lang="task.lang" :code="ex.input" />
        <div>Результат:</div>
        <CodeResult :code="ex.output" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUpdated, onMounted } from 'vue'
import Code from '@/components/ui/Code'
import CodeResult from '@/components/ui/CodeResult'

export default {
  name: 'LangPractice',
  components: { Code, CodeResult },
  props: ['task'],
  setup() {
    const MJ = window.MathJax
    const taskRef = ref(null)
    let promise = Promise.resolve()
    const reRenderTex = () => {
      promise = promise.then(() => MJ.typesetPromise([taskRef.value]))
    }
    onMounted(reRenderTex)
    onUpdated(reRenderTex)

    return {
      taskRef
    }
  }
}
</script>

<style scoped>

</style>
